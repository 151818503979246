import { Text2XL, Text5XL } from '../GeneralComponents';
import { useMemo } from 'react';
import Image from 'next/image';
import UseWindowSize from '@/utils/useWindowSize';
import cubes from '../../assets/images/icons/cubes.svg';
import locale from '../../locales/en/NativeLanding';
const reviewers: {
  [key: string]: {
    name: string;
    image: string;
    review: string;
    desc: string;
  };
} = {
  henry_wang: {
    name: 'Henry Wang',
    desc: 'Ex-Google PM Manager, 13.5k YouTube Subscribers',
    review:
      "“Jupitrr AI auto-generates B-roll videos, which makes video editing easier for content creators. I think it has a lot of potential and I'm excited to see what they come up with in terms of new features and iterations.”",
    image: 'henry.png'
  },
  jordan_wilson: {
    name: 'Jordan Wilson',
    desc: 'AI Expert & Podcaster, 7.25k YouTube Subscribers (Everyday AI)',
    review:
      "“If you're a content creator like me or a small business owner, putting out training or educational videos, you don't just want it to be a talking head. Jupitrr AI generates the right B-roll for your video by highlighting the right phrases.”",
    image: 'jordan.png'
  },
  novie_dizon: {
    name: 'Novie Dizon',
    desc: 'Finance Coach, 58k TikTok Followers',
    review:
      '“Jupitrr AI is a real time-saver for content creators like me, helping us find the perfect B-roll effortlessly! I absolutely love it!”',
    image: 'novie.png'
  },
  jay_kapoor: {
    name: 'Jay Kapoor',
    desc: 'Tech Creator, 600k Instagram Followers',
    review:
      '“Jupitrr AI is very useful and one of its kind tools for creators, especially podcasters. It saves time and resources to convert your audio recordings into beautiful presentable videos which you can share on your social media.”',
    image: 'jay.png'
  },
  thomas_choi: {
    name: 'Thomas Choi',
    desc: 'Content Creator, Googler | ex-Microsoft | ex-Amazon',
    review:
      '“Great experience using Jupitrr AI for generating B-roll or generating reels. It definitely makes my content creation process more productive by their 1-click B-roll generation.”',
    image: 'thomas.png'
  },
  curtis_pyke: {
    name: 'Curtis Pyke',
    desc: 'AI Expert, 133k YouTube Subscribers',
    review:
      '“It is wild. Not only you can generate b-roll faster and contextually, but it also does a lot of other things really well too. This is going to save a lot of people a lot of time and money.”',
    image: 'curtis.png'
  },
  bryan_guerra: {
    name: 'Bryan Guerra',
    desc: 'Ecommerce Coach, 78k YouTube Subscribers',
    review:
      "“Jupitrr AI simplifies the process of making professional quality videos and makes it quick and easy. It is perfect for the people who want to make money from videos but don't want to get in front of the camera.”",
    image: 'bryan.png'
  },
  david_mills: {
    name: 'David Mills',
    desc: 'Software Reviewer, 14.4k YouTube Subscribers (LearnWire)',
    review:
      "“Jupitrr AI is a game changer. I've always thought if something like this exists. There we have it.”",
    image: 'david.png'
  }
};

const ReviewCard = ({
  name,
  image,
  review,
  desc,
  index
}: {
  name?: string;
  image?: string;
  review?: string;
  desc?: string;
  index?: number;
}) => {
  return (
    <div
      key={index}
      className={`flex h-[300px] w-[297px] min-w-[297px] flex-col rounded-lg bg-white px-5 py-4 shadow-sm`}
    >
      <div className="flex flex-row items-center">
        <Image
          src={`/index/reviewers/${image}`}
          alt={name}
          width={60}
          height={60}
          className="mr-3"
        />
        <div className="flex flex-col">
          <div className=" font-600">{name}</div>
          <div className="text-sm text-grey-500">{desc}</div>
        </div>
      </div>
      <div className="mt-4 text-grey-600">{review}</div>
    </div>
  );
};

const numberOfCardsPerRow = 4;

const WhatCreatorsSay = () => {
  const { width } = UseWindowSize();
  const heightClass = 'flex w-full items-center justify-center';

  const firstRowData = useMemo(() => {
    return Object.keys(reviewers)
      .filter((r, i) => i < numberOfCardsPerRow)
      .map((key, index) => {
        const { name, image, review, desc } = reviewers[key];
        return (
          <ReviewCard
            key={index}
            name={name}
            image={image}
            review={review}
            desc={desc}
          />
        );
      });
  }, [reviewers, width]);
  const secondRowData = useMemo(() => {
    return Object.keys(reviewers)
      .filter((r, i) => i >= numberOfCardsPerRow)
      .map((key, index) => {
        const { name, image, review, desc } = reviewers[key];
        return (
          <ReviewCard
            key={index}
            name={name}
            image={image}
            review={review}
            desc={desc}
          />
        );
      });
  }, [reviewers, width]);
  // 'flex h-[260px] w-full items-center justify-center lg:h-[450px] min-[1680px]:h-[540px] ';
  return (
    <div
      className={`relative mt-8 bg-blue-600 py-24 ${heightClass}`}
      style={{
        backgroundImage: `url(${cubes.src})`,
        backgroundRepeat: 'repeat',
        backgroundPosition: '0 -30px',
        backgroundSize: 'auto' // Adjust the size as needed
      }}
    >
      <div className="z-20 flex w-full flex-col items-center">
        <div className="m-auto  mb-[70px]  max-w-screen-lg px-3 text-center md:px-0">
          <Text5XL className="mb-2 text-white">
            {locale.what_creators_say.header}
          </Text5XL>
          <Text2XL className=" text-white">
            {locale.what_creators_say.subheader}
          </Text2XL>
        </div>
        <div className="m-auto flex w-full max-w-screen-2xl flex-col items-start overflow-hidden min-[1260px]:justify-center ">
          <div className="no-scrollbar flex w-full flex-row gap-4 overflow-x-scroll px-3 min-[1260px]:justify-center">
            {firstRowData}
          </div>
          <div className="no-scrollbar mt-8 flex w-full flex-row gap-4 overflow-x-scroll px-3 min-[1260px]:justify-center">
            {secondRowData}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatCreatorsSay;
