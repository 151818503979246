import { SectionContent } from '../OldGeneralComponents';
import { Text2XL, TextXL } from '../GeneralComponents';
import Image from 'next/image';
import React from 'react';
import locale from '../../locales/en/NativeLanding';
const logosList = [
  { width: 200, src: 'as.png' },
  { width: 147, src: 'bbc.png' },
  { width: 242, src: 'bd.png' },
  { width: 129, src: 'gojek.png' },
  { width: 200, src: 'nyu.png' },
  { width: 141, src: 'bcc.png' },
  { width: 128, src: 'tmu.png' },
  { width: 146, src: 'unicef.png' },
  { width: 172, src: 'uow.png' },
  { width: 127, src: 'usc.png' }
];

const Logos = (): JSX.Element => (
  <div className="relative flex flex-col justify-center bg-grey-50 py-4 sm:py-16">
    {/* <SectionContent className=""> */}
    <Text2XL className="mx-auto mb-4 font-500">
      Trusted by organisations around the world
    </Text2XL>
    <div className="mx-auto grid max-w-[1088px] grid-cols-2 gap-4 md:grid-cols-5 lg:grid-cols-5">
      {logosList.map((logo, index) => (
        <div className="flex w-full justify-center" key={index}>
          <Image
            alt={logo.src}
            className="flex self-center"
            src={`/index/logos/${logo.src}`}
            key={index}
            height={100}
            width={logo.width}
          />
        </div>
      ))}
    </div>
    {/* </SectionContent> */}
  </div>
);

export default Logos;
