import {
  BASE_URL,
  INSTAGRAM_LINK,
  LINKEDIN_LINK,
  MAIN_EMAIL,
  TWITTER_LINK
} from './DocumentHead';
import { Colours } from './Colours';
import { ExternalUrls, PageNames } from '../utils/generalUtilities';
import { PRICING_SOURCE } from '../audiogram/utils/constant';
import { SectionContent } from './OldGeneralComponents';
import { logoHorizontal, logoWithName } from '../assets/generalAssets';
import Image from 'next/image';
import locale from '../locales/en/NativeLanding';
const SEO_BLOGS = [
  {
    link: 'https://jupitrr.com/product/ai-caption-generator-for-videos',
    name: 'AI Caption Generator'
  },
  {
    link: 'https://jupitrr.com/product/ai-youtube-video-maker',
    name: 'AI Youtube Video Maker'
  },
  {
    link: 'https://jupitrr.com/product/ai-slideshow-video-generator',
    name: 'AI Slideshow Generator'
  }
];
const footerData = [
  [
    {
      name: 'About Us',
      link: `${PageNames.ABOUT_US}?source=${PRICING_SOURCE.footer}`
    },
    {
      name: "What's new",
      link: `${PageNames.CHANGE_LOG}?source=${PRICING_SOURCE.footer}`
    },
    {
      name: 'FAQs',
      link: `${PageNames.FAQ}?source=${PRICING_SOURCE.footer}`
    }
  ],
  [
    {
      name: 'Instagram',
      link: INSTAGRAM_LINK
    },
    {
      name: 'Twitter',
      link: TWITTER_LINK
    },
    {
      name: 'LinkedIn',
      link: LINKEDIN_LINK
    },
    {
      name: 'Blog',
      link: `${BASE_URL}${PageNames.BLOG}`
    }
  ],
  [
    {
      name: 'Terms & Conditions',
      link: `${PageNames.TERMS_AND_CONDITIONS}?source=${PRICING_SOURCE.footer}`
    },
    {
      name: 'Privacy Policy',
      link: `${PageNames.PRIVACY_POLICY}?source=${PRICING_SOURCE.footer}`
    },
    {
      name: MAIN_EMAIL,
      link: `mailto:${MAIN_EMAIL}`
    }
  ],
  SEO_BLOGS
];

const EachColumn = (
  eachColData: {
    name: string;
    link: string;
  }[],
  key: number
) => {
  return (
    <div
      key={key}
      className={`mb-6 flex flex-col text-left last:mt-6 sm:mb-0 sm:mr-[48px] sm:last:mr-0 sm:last:mt-0`}
    >
      {eachColData.map((eachData, index) => {
        return (
          <a
            key={index}
            style={{ color: Colours.MediumGrey, marginBottom: 16 }}
            target="_blank"
            href={eachData.link}
            rel="noreferrer nofollow"
            className="text-sm"
          >
            {eachData.name}
          </a>
        );
      })}
    </div>
  );
};

const Footer = () => (
  <div className="bg-color-new-white pb-8 pt-[42px]">
    <SectionContent maxWidth={'1024px'}>
      <div className="flex flex-col justify-between gap-9 text-left text-color-medium-gray md:flex-row">
        <div className="mb:mb-0 mb-8 mt-2.5 flex w-2/3 flex-col text-left md:justify-start">
          <div>
            <Image
              priority
              src={logoWithName}
              width={108}
              height={36}
              alt={'jupitrr logo'}
            />
          </div>
          <div className="mt-[15px] max-w-[400px] text-base">
            {locale.footer.title}
          </div>
        </div>
        <div className="mt-2.5 flex w-full justify-between text-left">
          {footerData.map((eachColData, index) =>
            EachColumn(eachColData, index)
          )}
        </div>
      </div>
      <div className="mt-9 flex flex-col justify-between text-color-medium-gray md:flex-row">
        <div className="text-left">
          {`© Jupitrr ${new Date().getFullYear()} All Rights Reserved.`}
        </div>
      </div>
    </SectionContent>
  </div>
);

export default Footer;
