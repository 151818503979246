import { NewJPButton } from '../GeneralComponents';
import { ScreenTypes, useScreenType } from '../../hooks/useScreenType';
import { SectionContent } from '../OldGeneralComponents';
import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import locale from '../../locales/en/NativeLanding';
import parse from 'html-react-parser';
import useSectionContentWidth from '../../hooks/useSectionContentWidth';
const rectangle = require('../../assets/images/waitlist/rectangle.svg');
const appSumoImage = require('../../assets/images/seo/appSumoImage.svg');

export const ProductHuntBadge = () => {
  return (
    <a
      href="https://www.producthunt.com/posts/jupitrr-ai?embed=true&utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-jupitrr&#0045;ai"
      target="_blank"
      rel="noreferrer"
      className="mx-auto md:mx-0"
    >
      <img
        src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=471145&theme=light&period=weekly"
        alt="Jupitrr AI - Auto generate B roll visuals for content marketing videos | Product Hunt"
        style={{ width: '250px', height: '54px' }}
      />
    </a>
  );
};

const ForWho = () => {
  const targetAudiences = locale.waitlist.targetAudience;
  const [currentIndex, setCurrentIndex] = useState(0);

  const [currentString, setCurrentString] = useState(
    targetAudiences[currentIndex]
  );
  useEffect(() => {
    const interval = setInterval(() => {
      const nextIndex = (currentIndex + 1) % targetAudiences.length;
      setCurrentIndex(nextIndex);
      setCurrentString(targetAudiences[nextIndex]);
    }, 2500);

    return () => {
      clearInterval(interval);
    };
  }, [currentIndex]);
  return (
    <div className="text-box">
      <div className="left-0 inline-block text-blue-600" key={currentString}>
        {currentString}
      </div>
    </div>
  );
};

const HeroBanner = () => {
  const { sectionWidthInPixels } = useSectionContentWidth();
  const screenType = useScreenType();
  const isSmallScreen = screenType === ScreenTypes.mobile || ScreenTypes.tab;

  return (
    <div className="mt-8 overflow-hidden">
      <SectionContent className="relative z-10" maxWidth={sectionWidthInPixels}>
        <div className="relative z-20 flex flex-col items-center text-center">
          <h1 className="mb-6 flex flex-col items-center text-3xl font-bold text-grey-800 md:text-5xl lg:text-6xl">
            <div className="flex">
              <div className="relative inline-block w-full">
                <div className="">
                  {parse(locale.waitlist.header)}
                  {/* Note: Intentional Space */}
                  {` `}
                  <ForWho />
                </div>
              </div>
            </div>
          </h1>
          <h3 className="mb-8 w-[80%] text-base text-grey-500 sm:text-lg md:max-w-[90%] md:text-xl">
            {parse(locale.waitlist.subheader)}
          </h3>

          <Link href={'/create-slideshow'}>
            <NewJPButton
              className="!px-7 !py-2 md:!py-4 md:!text-lg"
              id={'try-ai-vieo-maker'}
              text={locale.waitlist.conversion_text}
              size="lg"
            />
          </Link>
          <div className="mb-16 mt-16 flex w-full flex-col justify-center md:mt-4 md:flex-row  md:justify-between">
            <div className="mb-8 text-center md:mb-0 md:text-left">
              <Image
                src={`/index/loved.png`}
                alt={'loved by'}
                width={265}
                height={43}
                className="mx-auto mb-3 object-contain md:mr-auto"
              />
              {parse(locale.waitlist.loved)}
            </div>
            <div className="mb-8 flex items-center text-center md:mb-0 md:text-left">
              <Image
                src={appSumoImage}
                alt="App Sumo select"
                width={185}
                height={44}
                className="mx-auto object-contain md:mr-auto"
              />
            </div>
            <ProductHuntBadge />
          </div>
        </div>
        <div>
          <Image
            width={isSmallScreen ? '220' : '414'}
            height={isSmallScreen ? '100' : '158'}
            className="absolute -bottom-[15px] -left-[164px] z-10"
            src={rectangle}
            alt={'rectangle bg'}
          />
        </div>
        <Image
          width={isSmallScreen ? '220' : '414'}
          height={isSmallScreen ? '100' : '158'}
          className="absolute -right-[140px] top-5 z-10"
          src={rectangle}
          alt={'rectangle bg'}
        />
      </SectionContent>
    </div>
  );
};

export default HeroBanner;
