import Image from 'next/image';
import React, { useEffect, useRef, useState } from 'react';

const VideoGif = ({
  videoSrc,
  placeholderImageSrc,
  width,
  height,
  className
}: {
  videoSrc: string;
  placeholderImageSrc: string;
  width?: number;
  height?: number;
  className?: string;
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const videoGifRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const videoElement = videoGifRef.current;

    if (videoElement && videoSrc) {
      videoElement.addEventListener('loadeddata', () => {
        setIsLoaded(true);
      });

      videoElement.src = videoSrc;
      videoElement.load();
    }

    return () => {
      if (videoElement && videoSrc) {
        videoElement.removeEventListener('loadeddata', () => {});
      }
    };
  }, []);

  return (
    <div className="relative flex h-full w-full justify-center">
      {!isLoaded && (
        <img
          src={placeholderImageSrc}
          alt="Loading..."
          className={`absolute z-10 h-full w-full rounded-3xl object-cover`}
          width={width}
          height={height}
          loading="lazy"
        />
      )}
      <video
        width={width}
        height={height}
        ref={videoGifRef}
        autoPlay
        loop
        playsInline
        muted
        className={`h-full rounded-3xl bg-white object-cover ${className}`}
        preload="none"
      />
      <div
        className={`absolute left-0 top-0 z-10 h-full w-full rounded-3xl bg-transparent`}
      ></div>
    </div>
  );
};

// "https://via.placeholder.com/1920x1080"

// "https://www.sample-videos.com/video123/mp4/720/big_buck_bunny_720p_1mb.mp4"

export default VideoGif;
