import { DEMO_VIDEO_URL } from '@/models/constant';
import { NewJPButton, Text6XL } from '../GeneralComponents';
import { ScreenTypes, useScreenType } from '../../hooks/useScreenType';
import { SectionContent } from '../OldGeneralComponents';
import { useRouter } from 'next/router';
import Image from 'next/image';
import locale from '../../locales/en/NativeLanding';
import useSectionContentWidth from '../../hooks/useSectionContentWidth';

const triangles = require('../../assets/images/icons/triangles.svg');
const dots = require('../../assets/images/icons/dots.svg');

const FOOTER_IMAGE =
  'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/new_index/images/bottom-visual.png';
const Greetings = () => {
  const screenType = useScreenType();

  const isNotDesktop = screenType !== ScreenTypes.desktop;
  return (
    <div className="bg-black py-16 text-white ">
      <SectionContent maxWidth={'1220px'} className="md:px-4">
        <div className="relative flex flex-col items-center justify-between xl:flex-row">
          <Image
            className={`absolute hidden md:flex  ${
              isNotDesktop ? 'right-[40px]' : 'right-[-96px]'
            } top-1/3 flex h-[90px] w-[90px] items-center justify-center overflow-hidden rounded-[10px] shadow-lg`}
            src={triangles}
            alt={'sample'}
          />

          <div className="flex flex-col md:w-[550px] xl:mr-12">
            <div className="text-3xl lg:text-5xl">
              {locale.greetings.header}
            </div>

            <div className="my-8 flex flex-col">
              {locale.greetings.info.map((i) => (
                <div key={i} className="mb-6 text-base font-extralight">
                  {i}
                </div>
              ))}
            </div>

            <div className="flex items-center">
              <Image
                alt={'co-founder-image'}
                width={52}
                height={52}
                src={locale.greetings.profile_image_url}
                className="mr-4 h-14 w-14 rounded-full"
              />

              <div className="text-lg">{locale.greetings.profile_info}</div>
            </div>
          </div>

          <iframe
            src={`${DEMO_VIDEO_URL}?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true`}
            className={
              'relative mt-8 w-full overflow-hidden rounded-3xl md:max-w-[550px]  xl:mt-0 xl:h-[550px] xl:w-[1000px]'
            }
            allowFullScreen={true}
            style={{
              maxHeight: 'calc( (550px - 24px) / 100 * 65 )',
              height: 'calc( (100vw - 24px) / 100 * 65 )'
            }}
          />
          <Image
            className={`absolute bottom-[-60px] hidden md:flex ${
              isNotDesktop ? 'left-[-80px]' : 'left-[-150px]'
            } w-90] mb-7 ml-11 flex h-[90px] rotate-90 items-center justify-center overflow-hidden rounded-[10px]  shadow-lg `}
            src={triangles}
            alt={'sample'}
          />
        </div>
      </SectionContent>
    </div>
  );
};

const FooterConversionCard = () => {
  const router = useRouter();
  const { sectionWidthInPixels } = useSectionContentWidth();
  const screenType = useScreenType();
  const isSmallScreen = screenType === ScreenTypes.mobile || ScreenTypes.tab;
  return (
    <div className="relative flex flex-col items-center bg-blue-600 text-white">
      <SectionContent maxWidth={sectionWidthInPixels}>
        <div className="flex flex-col items-center">
          <Text6XL className="mb-8 w-full pt-16 text-center text-white md:w-full lg:w-[787px] lg:px-0">{`Still editing B-roll yourself? AI does it 10x faster.`}</Text6XL>

          <div className=" relative flex w-full flex-col items-center">
            <NewJPButton
              colorType="white-blue"
              size="xl"
              className="flex justify-center"
              text={'Try Jupitrr AI for free'}
              onClick={() => router.push('/create-slideshow')}
            />
            <Image
              className="absolute -bottom-[30px] -right-[140px] z-0 mb-7 ml-11 hidden h-64 w-64 rotate-90 items-center justify-center overflow-hidden rounded-[10px] shadow-lg   md:flex "
              src={dots}
              alt={'dots'}
            />

            <Image
              className="absolute -left-[120px] top-0 z-0 mb-7 ml-11 hidden h-64 w-64 rotate-90 items-center justify-center overflow-hidden rounded-[10px] shadow-lg md:flex "
              src={dots}
              alt={'dots'}
            />

            <div className="mt-6 h-[180px] w-[700px] sm:h-[300px] md:h-[500px] lg:w-full">
              <div className="h-[180px] w-[700px] sm:h-[300px] md:h-[500px] lg:w-full"></div>
              <Image
                className="absolute bottom-0 left-0 z-50 mt-10 flex w-full overflow-hidden rounded-t-xl bg-white"
                src={FOOTER_IMAGE}
                height={isSmallScreen ? 550 : 755}
                width={isSmallScreen ? 700 : 1048}
                alt={'footer image - try jupitrr for free'}
              />
            </div>
          </div>
        </div>
      </SectionContent>
    </div>
  );
};

export { Greetings, FooterConversionCard };
