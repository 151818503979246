import { ScreenTypes, useScreenType } from './useScreenType';
import { useEffect, useState } from 'react';

const useSectionContentWidth = () => {
  const screenType = useScreenType();
  const [sectionWidth, setSectionWidth] = useState(null);

  useEffect(() => {
    let width = null;
    switch (screenType) {
      case ScreenTypes.mobile:
        width = 340;
        break;
      case ScreenTypes.tab:
        width = 600;
        break;
      case ScreenTypes.web:
        width = 800;
        break;
      case ScreenTypes.desktop:
        width = 1024;
        break;
      default:
        width = 800; // Default width for unknown screen type
    }
    setSectionWidth(width);
  }, [screenType]);

  const sectionWidthInPixels =
    sectionWidth !== null ? `${sectionWidth}px` : null;

  return { sectionWidth, sectionWidthInPixels };
};

export default useSectionContentWidth;
